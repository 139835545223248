<template>
  <el-container class="container" ref="container" style="display: flex;flex-direction:column;" >
    <div class="myscrollbar" style="width:100%;overflow:auto">
    <div class="flex_row" style="justify-content:space-between;
        width:100%;margin-top:10px;min-width:800px;" :style="'max-width:'+divwidth+'px;'">
      <div class="flex_col" style="width:56%;flex-grow:1;flex-shrink:1;">
        <div class="flex_row_left" style="width:100%;margin-bottom:5px;">
          <el-autocomplete  autocomplete="on" :fetch-suggestions="CallerNumAutoComp" :size="size" 
          v-model="CallerNum" :maxlength="11" clearable  @select="queryOrderList('CallerNum',CallerNum)"
                @keyup.enter.native="EnterFun($event,'CallerNum',CallerNum)" style="margin-left:10px;width:35%;flex-grow:1;flex-shrink:1">
                <el-button type="success" slot="prepend" :size="size" plain class="button"
                  @click="queryOrderList('CallerNum',CallerNum)">呼叫号</el-button>
          </el-autocomplete>
          <el-autocomplete  autocomplete="on" :fetch-suggestions="mobileAutoComp" :size="size" 
          v-model="orderHead.mobile" :maxlength="11" clearable @select="queryOrderList('mobile',orderHead.mobile)"
                @keyup.enter.native="EnterFun($event,'mobile',orderHead.mobile)" style="margin-left:10px;width:35%;flex-grow:1;flex-shrink:1">
                <el-button type="success" slot="prepend" :size="size" plain class="button"
                  @click="queryOrderList('mobile',orderHead.mobile)">联系</el-button>
          </el-autocomplete>
          <el-autocomplete  autocomplete="on" :fetch-suggestions="YajinAutoComp" :size="size" 
          v-model="orderHead.m_yajin" :maxlength="7" clearable 
                @keyup.enter.native="EnterFun($event,'m_yajin',orderHead.m_yajin)" style="margin-left:10px;width:100px;flex-grow:1;flex-shrink:1;">
                <el-button type="success" slot="prepend" :size="size" plain class="button" 
                  @click="queryOrderList('m_yajin',orderHead.m_yajin)">押</el-button>
          </el-autocomplete>
        </div>
        <div class="flex_row_left" style="width:100%;margin-bottom:5px;">
          <el-autocomplete  autocomplete="off" :fetch-suggestions="ContactAutoComp" :size="size" v-model="orderHead.contact" 
          :maxlength="50" clearable 
                @keyup.enter.native="EnterFun($event,'contact',orderHead.contact)" style="margin-left:10px;width:40%;flex-grow:1;flex-shrink:1;">
                <el-button type="success" slot="prepend" :size="size"  plain class="button" 
                  @click="queryOrderList('contact',orderHead.contact)">名称</el-button>
          </el-autocomplete>
          <el-autocomplete  autocomplete="off" :fetch-suggestions="SyzbhAutoComp" :size="size" 
          v-model="orderHead.m_syzbh" :maxlength="10" clearable @select="queryOrderList('m_syzbh',orderHead.m_syzbh)"
                @keyup.enter.native="EnterFun($event,'m_syzbh',orderHead.m_syzbh)" style="margin-left:10px;width:50%;flex-grow:1;flex-shrink:1;">
                <el-button type="success" slot="prepend" :size="size" plain class="button"
                  @click="queryOrderList('m_syzbh',orderHead.m_syzbh)">使用证</el-button>
                  <el-button type="danger" slot="append" icon="el-icon-edit" :size="size" plain 
                  style="width:42px;padding:10px 0px;" 
                  @click="ModiflySyzbh"></el-button>
          </el-autocomplete>
        </div>
        <div class="flex_row_left" style="width:100%;margin-bottom:5px;">
          <el-input  autocomplete="off" :size="size" v-model="orderHead.contactaddr" maxlength="150" clearable 
                @keyup.enter.native="EnterFun($event,'contactaddr',orderHead.contactaddr)" style="margin-left:10px;">
                <el-button type="success" slot="prepend" :size="size" plain class="button" 
                  @click="queryOrderList('contactaddr',orderHead.contactaddr)">地址</el-button>
              <el-select v-model="orderHead.station" slot="append" 
                  @change="changeSelect" placeholder="站点" style="width:90px;">
                <el-option
                  v-for="item in stationLists"
                  :key="item.station"
                  :label="item.station"
                  :value="item.station" style1="height:28px;">
                </el-option>
              </el-select>
          </el-input>
          
        </div>
        <div class="flex_row_left" style="width:100%;margin-bottom:5px;">
          <el-autocomplete  autocomplete="off" :fetch-suggestions="CmemoAutoComp" :size="size" 
          v-model="orderHead.m_cmemo" :maxlength="50" clearable 
                @keyup.enter.native="EnterFun($event,'m_cmemo',orderHead.m_cmemo)" style="margin-left:10px;width:40%;flex-grow:1;flex-shrink:1;">
                <el-button type="success" slot="prepend" :size="size" plain class="button" 
                  @click="queryOrderList('m_cmemo',orderHead.m_cmemo)">备</el-button>
          </el-autocomplete>
          <el-time-select
            v-model="orderHead.timeval"  :size="size" style="margin-left:10px;width:110px;flex-shrink:0;"
            :picker-options="{
              start: '08:00',
              step: '00:30',
              end: '18:00'
            }"
            placeholder="选择时间">
          </el-time-select>
          <el-input  autocomplete="off" :size="size" v-model="orderHead.m_identity" maxlength="18" clearable 
                @keyup.enter.native="EnterFun($event,'m_identity',orderHead.m_identity)" style="margin-left:10px;width:50%;flex-grow:1;flex-shrink:1;">
                <el-button type="success" slot="prepend" :size="size" plain class="button" 
          @click="queryOrderList('m_identity',orderHead.m_identity)">证</el-button>
          </el-input>
        </div>
      </div>
      <div class="flex_col" style="">
          <el-button type="success"  :size="size" style="width:100px;margin:0 0px 5px 10px;padding-left:0px;padding-right:0px;" 
          @click="MakeOrder('1','15KG(国产)')">15KG(国产)</el-button>
          <el-button type="primary"  :size="size" style="width:100px;margin:0 0px 5px 10px;padding-left:0px;padding-right:0px;" 
          @click="MakeOrder('2','15KG(进口)')">15KG(进口)</el-button>
          <el-button type="success"  :size="size" style="width:100px;margin:0 0px 5px 10px;padding-left:0px;padding-right:0px;" 
          @click="MakeOrder('3','50KG')">50KG</el-button>
          <el-button type="success"  :size="size" style="width:100px;margin:0 0px 5px 10px;padding-left:0px;padding-right:0px;" 
          @click="MakeOrder('9','报 修')">报 修</el-button>
      </div>
      <div class="flex_col" style="width:30%;flex-grow:1;flex-shrink:1;margin:0 10px 5px;">
          <div class="flex_row_left input-with-select" style="width:100%;margin-bottom:5px;">
              <el-button type="success"  :size="size" plain class="button" 
                  @click="SelectWork">选送气工</el-button>
              <el-select v-model="orderHead.m_worker" clearable :size="size"
                  placeholder="送气工" style="margin-left1:20px;flex-grow:1">
                <el-option
                  v-for="(item,index) in worksList"
                  :key="index"
                  :label="item.Contact"
                  :value="item.Contact">
                  <span style="float: left">{{item.Contact}}{{item.Mobile}}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{ item.m_station}}</span>
                </el-option>
              </el-select>
          </div>
          <el-input  autocomplete="off" :size="size" v-model="orderHead.m_scantime" maxlength="50" clearable readonly
                 style="margin-bottom:5px;">
                <el-button type="success" slot="prepend" :size="size" plain class="button" style1="margin:0 10px;" 
                  @click="getOrderList('送达')">送达</el-button>
          </el-input>
          <el-input  autocomplete="off" :size="size" v-model="orderHead.zdy2" maxlength="50" clearable 
                style="margin-bottom:5px;">
                <el-button type="success" slot="prepend" :size="size" plain class="button" style1="margin:0 10px;" 
                  @click="getOrderList('保险')">保险</el-button>
          </el-input>
          <el-input  autocomplete="off" :size="size" v-model="orderHead.anjian" maxlength="50" clearable readonly
                 style="margin-bottom:5px;">
                <el-button type="success" slot="prepend" :size="size" plain class="button" style1="margin:0 10px;" 
                  @click="getOrderList('安检')">安检</el-button>
          </el-input>

      </div>
    </div>
    </div>
    <div class="flex_row_left calltool" style="flex-wrap:wrap;width:100%;margin-top:0px;background: #f5f7fa;padding-bottom:5px;">
      <el-switch v-model="canCall" :disabled="!(StaffID)" v-if="isPc" active-color="#409eff" inactive-color="#ff4949"
           :size="size" @change="changSwitch" style="margin:0 10px;margin-top:10px;" >
      </el-switch>
      <el-button type="success" plain :size="size" style="margin:0 10px;margin-top:5px;" 
          @click="getOrderList('查待审')">查待审</el-button>
      <el-button type="success" plain :size="size" style="margin:0 10px;margin-top:5px;" 
          @click="getOrderList('审核未派')">审核未派</el-button>
      <el-button type="primary" :size="size" style="margin:0 10px;margin-top:5px;" 
          @click="EditOrder()">保存审核</el-button>
      <el-button type="success" plain :size="size" style="margin:0 10px;margin-top:5px;" 
          @click="getOrderList('已派未达')">已派未达</el-button>
      <el-button type="success" plain :size="size" style="margin:0 10px;margin-top:5px;" 
          @click="getOrderList('完成')">送达完成</el-button>
      <el-button type="primary" :size="size" style="margin:0 10px;margin-top:5px;" 
          @click="CancelOrder()">取消订单</el-button>
      <el-button type="success" plain :size="size" style="margin:0 10px;margin-top:5px;" 
          @click="getOrderList('查取消')">查取消</el-button>
      <el-button type="success" plain :size="size" style="margin:0 10px;margin-top:5px;" 
          @click="getOrderList('查匹配度')">查匹配度</el-button>
    </div>
    <!-- <div style="height:5px;background: #efeff4;"></div> -->
    <el-main ref="elmaincards"  style="justify-content: flex-start;display: flex;
    flex-direction:column;overflow-y: auto;"
      >
        <el-table :height="tableHeight" :size="size" ref="multipleTable" :data="orderlists" highlight-current-row 
				v-loading="listLoading" element-loading-text="Loading..." :fit="true" @current-change="handleCurrentChange"
				element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.65)"
				border tooltip-effect="dark" :header-cell-style="tableHeaderColor" >
<div slot="empty" style="float:left">暂无数据</div>
					<el-table-column prop="px" label="序" width="38" align="center" show-overflow-tooltip>
            <div slot-scope="scope" style="text-align:left;font-size:12px;">
                {{scope.row.px}}</div>
          </el-table-column>
					<el-table-column prop="m_xdtime" label="下单时间" width="110" min-width="60"  show-overflow-tooltip>
            <div slot-scope="scope" style="text-align:left;font-size:13px;">
                {{scope.row.m_xdtime}}</div>
          </el-table-column>
          <el-table-column prop="m_sourece" label="来源" width="50"  align="center">
            <div slot-scope="scope" style="text-align:center;font-size:12px;">
                {{scope.row.m_sourece}}</div>
          </el-table-column>
					<el-table-column prop="yyr" label="制单人" width="65"  align="center"></el-table-column>
          <el-table-column prop="YYSJ" label="预约送达" width="140" min-width="60"  show-overflow-tooltip>
            <div slot-scope="scope" style="text-align:left;font-size:13px;">
                {{scope.row.YYSJ}}</div>
          </el-table-column>
          <el-table-column prop="m_mobile" label="联系电话" width="110" min-width="60" show-overflow-tooltip></el-table-column>
          <!-- <el-table-column prop="m_lxmobile" label="呼叫电话" width="80" min-width="60" show-overflow-tooltip>
            <template slot-scope="scope">
              <div style="color:#f00;cursor:pointer;" @click="clearLxmobile(scope.row.recid)" v-if="scope.row.m_scantime==''">
                {{scope.row.m_lxmobile}}</div>
              <div v-else>{{scope.row.m_lxmobile}}</div>
            </template>
          </el-table-column> -->
          <el-table-column prop="m_station" label="站点" width="60"></el-table-column>
          <el-table-column prop="m_syzbh" label="使用证" width="90"></el-table-column>
          <el-table-column prop="contact" label="名称" width="140" min-width="60" show-overflow-tooltip></el-table-column>
          <el-table-column prop="YYLX" label="服务内容" width="80" min-width="60" show-overflow-tooltip></el-table-column>
          <el-table-column prop="m_stats" label="状态" width="60"></el-table-column>
          <el-table-column prop="m_caddress" label="送气地址" width="300" min-width="60" show-overflow-tooltip>
            <div slot-scope="scope" style="font-size:13px;">
                {{scope.row.m_caddress}}</div>
          
          </el-table-column>
          <el-table-column prop="m_price" label="单价" width="60"></el-table-column>
          <el-table-column prop="anjian" label="安检" width="60" min-width="60" show-overflow-tooltip></el-table-column>
          <el-table-column prop="m_cmemo" label="备注" width="60" min-width="60" show-overflow-tooltip></el-table-column>
          <el-table-column prop="m_worker" label="送气工" width="70" min-width="60" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="m_scantime" label="送达" width="170" min-width="60" show-overflow-tooltip></el-table-column>
          <el-table-column prop="m_lasttime" label="最近操作" width="160" min-width="60" show-overflow-tooltip></el-table-column>
          <el-table-column prop="zdy2" label="保险" width="60" min-width="60" show-overflow-tooltip></el-table-column>
          <el-table-column prop="m_identity" label="身份证" width="70" min-width="60" show-overflow-tooltip></el-table-column>
          <el-table-column prop="khbh" label="客户编号" width="100" min-width="60" show-overflow-tooltip></el-table-column>
          <el-table-column prop="m_usecode" label="气瓶号" width="90" min-width="60" show-overflow-tooltip></el-table-column>
          <el-table-column prop="m_yajin" label="押金" width="50" show-overflow-tooltip></el-table-column>
          <el-table-column prop="m_kpusecode" label="空瓶号" width="90" min-width="60" show-overflow-tooltip></el-table-column>
          <el-table-column prop="match" label="匹配" width="50"  show-overflow-tooltip></el-table-column>
          <el-table-column prop="upflag" label="UP" width="45"></el-table-column>

        </el-table>
    </el-main>
    
        <el-pagination style="margin-bottom:5px;" 
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :current-page.sync="pageno"
        :page-sizes="[10,15,30,60,100]"
        :page-size="pagesize"
        :layout="pagelayout"
        :total="totalcount">
      </el-pagination>
    
    <el-drawer
      :visible.sync="showSelectWork"
      direction="btt"  :wrapperClosable="true" :withHeader="false"
      custom-class="" title="送气工定位" 
      ref="drawerSelectWork" :size="isPc?'100%':'100%'"
      >
      <!-- <div class="demo-drawer__content" > -->
      
    
    <div style="width:100%;height:100%;position:absolute;z-index: 800" >
     <baidu-map :center="{lng:121.67968256587015,lat:31.538427690383923}" :zoom="12" @ready="handler" 
      style="width:100%;height:100%;border-top: 1px solid #a1a3a7"></baidu-map>
    </div>
    <div style="padding-top:12px;padding-right:20px;position:absolute;width:100%;z-index: 900" >
    <el-row ref="toolbar">
      <el-col :span1="24" >
        
          <div style="display:flex;align-items:center;float:right;">
            <el-button-group>
              
  <el-button type="success" :size="size" plain style1="margin-left:10px;"
  @click="showSelectWork=false" v-if="canEditAddress">{{custLocation.ContactAddr}}</el-button>
  <el-button type="primary" :size="size" icon="el-icon-close" @click="showSelectWork=false"></el-button>
  <el-button type="success" :size="size" plain v-if="1==0 && anEditAddress">保存</el-button>
</el-button-group>
              <!-- <el-input :size="size"  v-model="custLocation.ContactAddr" placeholder="客户地址"  readonly
              style="width: 300px;margin-left:20px;" >
              <i  @click="saveaddress" slot="prefix" class="el-input__icon el-icon-location" style="cursor:pointer;"></i>
                <el-button type="success" slot="append" :size="size" plain class="button" style11="margin-left:20px;" 
                  @click="saveaddress">保存</el-button>
              </el-input> -->
            
          </div>
        
      </el-col>
      </el-row>
      </div>
    </el-drawer>  
  </el-container>
</template>

<script>
/* eslint-disable */
import sfutil from '../../Api/identity.js';
//import loadBMap from '../../plugins/loadBMap.js'
export default {
  data() {
    return { 
      size: 'medium',//medium,small,mini,
      divwidth:950,
      elmaincard:500,
      elmaincardh:700,
      isPc:false,
      stationList:[],//站点列表
      worksList:[],//送气工列表-过滤站点
      worksLists:[],//送气工列表-全部站点
      canCall:false,
      StaffID:"",//弹屏获取的uc3账号
      StaffName:"",//Uc3名称
      CallerNum:"",//接收到的呼叫电话
      tableHeight:500,//表格高度
      CustomerDrawer:{},
      orderHead:{//客户信息,订单头
        contact:"",station:"",contactaddr:"",mobile:"",m_identity:"",
	      m_syzbh:"",m_cmemo:"",m_yajin:"",m_worker:"",zdy2:"",m_lxmobile:"",timeval:""
      },
      CallerNumAuto:[],//自动建议录入
      //mobileAuto:[],//自动建议录入
      CmemoAuto:[{value:"送前电话联系"},],
      YajinAuto:[{value:"0"},{value:"1"},],
      SyzbhAuto:[],
      orderlists:[],
      listLoading:false,//表格加载时
      xsrecid:0,//销售订单的recid
      stationLists:[],//站点列表
      showSelectWork:false,//显示送气工开关
      canEditAddress:false,
      custLocation:{address:'',},//客户地址
      worksLocation:[],//送气工地址
      worksLocationList:[],//送气工历史位置
      markers:[],//地图覆盖物
      canEditAddress:false,//能否修改地址
      pageno:1,
      pagesize:10,
      totalcount:0,//记录总数
      pagelayout:"total,sizes,prev,pager,next,jumper",
      mytimers:[],//定时器数组，便于清理
    }
  },
  computed: {
    tabBarWidth(){
      return this.$store.getters.GettabBarInfo("tabBarWidth");
    },
    mobileAuto() {
      return this.$store.getters.GetmobileAuto;
    },
  },
  watch: {
    tabBarWidth(){
      this.initDivSize();
    }
  },
  methods: {
    handler({BMap, map}){
      console.log("地图来了。。。")
      this.map=map
      this.map.centerAndZoom(new BMap.Point(121.64705610974767,31.611031028703508), 12)
      this.map.enableScrollWheelZoom(true);
      this.map.addControl(new BMap.GeolocationControl({anchor: BMAP_ANCHOR_BOTTOM_LEFT})) //创建一个地图定位控件
      this.map.addControl(new BMap.NavigationControl({ anchor: BMAP_ANCHOR_TOP_LEFT, type: BMAP_NAVIGATION_CONTROL_SMALL }))
      this.mytimers.push(setTimeout(() => {
        this.getCustLocation()
      }, 10));
    },
    ModiflySyzbh(){
      if (this.StaffID==""){
        this.$message.error("此操作无权限");
        return;
      }
      let mobile=this.$api.myFilter(this.orderHead.mobile)
      if (!this.$api.checkMobile(mobile)) {
        this.$message.error("联系电话格式不对");
        return;
      }
      let m_syzbh=this.$api.myFilter(this.orderHead.m_syzbh)
      if (m_syzbh && /^[0-9]\d{6,7}$/.test(m_syzbh)==false){
        this.$message.error("使用证有错误7-8位");
        return;
      }
      this.$confirm("修改【"+mobile+"】的使用证为["+m_syzbh+"]吗?", "确认操作", {
          confirmButtonText: "确定",cancelButtonText: "取消",
          type: "info",iconClass: "el-icon-question",
        })
        .then(() => {
          let data = {
            procext:"order",
            lx: "modiflysyzbh",
            sqlone:mobile,
            sqltwo:m_syzbh
          };
          this.$api.post("",data,
            (res) => {
              if (res["status"] == "1") {
                let js=res["js"];
                if (js=="1"){
                  this.$message.success(res["message"]);
                  this.orderlists.forEach((item)=>{
                    if (item.m_mobile==mobile){
                      item.m_syzbh=m_syzbh
                    }
                  })
                  return
                }
              } 
              this.$message.error(res["message"]);
              if (res["status"] == "-2") {
                this.$parent.$parent.$parent.swapToken();
              }
            },
            (failure) => {
              //this.showUserInfo = false;
              this.$message.error(failure);
            }
          );
        })
      .catch(() => {});
    },
    handleSizeChange(val) {
      this.pagesize=val
      let pagesizes=JSON.parse(localStorage.getItem("pagesizes") || "{}");
      pagesizes["callsystem"]=val
      localStorage.setItem("pagesizes",JSON.stringify(pagesizes))
      if (this.totalcount==0) return
      this.getOrderList('mobile','mobile',this.pageno)
    },
    handlePageChange(val) {
      if (this.totalcount==0) return
      this.getOrderList('mobile','mobile',val)
    },
    getCustLocation() {
      let mobile=this.orderHead.mobile
      if (mobile.length!=8 && mobile.length!=11){
        mobile=this.CallerNum
      }
      let data = {
        lx: "getWorkerLocation",
        sqlone:mobile,
        procext:"order",
      };
      this.$api.post("",data,
        (res) => {
          if (res["status"] == "1") {
            this.canEditAddress=res["canedit"]==="0" ? false : true;
            this.custLocation=res.records[0];
            this.worksLocation=res.recordc;
            this.worksLocationList=res.recordd;
            
              this.mytimers.push(setTimeout(() => {
                this.dispWorkLocation();
              }, 1000));
            
          }else
            this.$message.error(res["message"]);
          if (res["status"] == "-2") {
            this.$parent.$parent.$parent.swapToken();
          }
        },
        (failure) => {}
      );
    },
    SelectWork() {
      var that=this
      let mobile=this.orderHead.mobile
      if (mobile.length!=8 && mobile.length!=11){
        mobile=this.CallerNum
      }
      console.log(mobile.length)
      if (mobile.length!=8 && mobile.length!=11){
        this.$message.error("请输入联系电话");
        return
      }
      this.showSelectWork=true;
      if ((typeof this.map) != 'undefined' || this.map==null){
        this.getCustLocation()
      } 
    },
    dispWorkLocation(){
      var that = this
      if (!this.showSelectWork) return;

      if ((typeof this.map) == 'undefined') return;
      
      this.map.clearOverlays();
      //this.map.removeOverlay(preMarker);
      let c=this.custLocation
      if (c.length==0) return
      //this.markers=[];
      let iconWork2 = new BMap.Icon("http://www.yh59481717.com/images/map-6.png", new BMap.Size(44, 50));
      let iconWork = new BMap.Icon("http://www.yh59481717.com/images/map-4.png", new BMap.Size(44, 50));
      let iconStat = new BMap.Icon("http://www.yh59481717.com/images/map-2.png", new BMap.Size(44, 50));
      let iconLine = new BMap.IconSequence(new BMap.Symbol(BMap_Symbol_SHAPE_BACKWARD_OPEN_ARROW, {
            scale: 0.6,//图标缩放大小
            strokeColor:'#fff',//设置矢量图标的线填充颜色
            strokeWeight: '2',//设置线宽
        }), '10', '30');
      let points =new BMap.Point(c.statXY.split("|")[0],c.statXY.split("|")[1]);
      //let p1=c.statXY.split("|")[0];
      //let p2=c.statXY.split("|")[1];
      //let point = new BMap.Point(p1,p2);
      //this.map.centerAndZoom(point,14)
      let label = new BMap.Label("<div style='padding:1px;font-size:9px;'>"+c.station+"</div>",{point:points,offset:new BMap.Size(-6,-20)});
      label.setStyle({background:"#bbfc9b",color:"#333",cursor:"pointer"});
      let marker = new BMap.Marker(points, { enableDragging: true,icon:iconStat});
      marker.setLabel(label);
      this.map.addOverlay(marker);

      let pointc = new BMap.Point((c.custXY?c.custXY:c.statXY).split("|")[0],(c.custXY?c.custXY:c.statXY).split("|")[1]);
this.map.centerAndZoom(pointc,14)
      label = new BMap.Label("<div style='padding:1px;font-size:9px;'>"+c.Contact+"</div>",{point:pointc,offset:new BMap.Size(3,-23)});
      label.setStyle({borderColor:"#808080",color:"#333",cursor:"pointer"});
      marker = new BMap.Marker(pointc, { enableDragging: true});
      marker.setLabel(label);
      if (1==0 && this.canEditAddress){
        marker.addEventListener('dragend', function(e) {
          typographyBreakpointsVue.getAddrByPoint(e.point);
        })
        marker.addEventListener('click', function(e) {
          that.EditAddrByPoint(e.point);
        })
      }
      this.markers.push(marker);
      this.map.addOverlay(marker);
      marker.setAnimation(BMAP_ANIMATION_BOUNCE);

      let pointw=null,pointwp=null
      this.worksLocation.forEach((xx,index) => {
        pointwp=null;
        let works=[]
        //console.log(that.worksLocationList)
        if (this.worksLocationList.length>0){
          works=this.worksLocationList.filter(e=>e.mobile==xx.workmobile && e.recid>xx.precid)
        }
        if (works.length>0){
          xx.lng=works[0].lng;
          xx.lat=works[0].lat;
          this.mytimers.push(setTimeout(() => {
            that.showworkpolyline(index,works);
          }, 100));
        }
          if ((typeof xx.marker) == 'undefined'){
            pointwp = new BMap.Point(xx.lng,xx.lat);
            label = new BMap.Label("<div style='padding:1px;font-size:9px;'>"+xx.station+"-"+xx.workname+"</div>",
            {point:pointwp,offset:new BMap.Size(-6,-20)});
            label.setStyle({background:"#bbfc9b",color:"#333",cursor:"pointer"});
            marker = new BMap.Marker(pointwp, { enableDragging: true,icon:iconWork});
            if (xx.workname==this.orderHead.m_worker){
              marker.setIcon(iconWork2)
              label.setStyle({background:"#a0cfff",color:"#333",cursor:"pointer"});
            }
            label.addEventListener('click', function(e) {
              that.SetWorker(xx.workname);
            })
            marker.setLabel(label);
            this.map.addOverlay(marker);
            //marker.setAnimation(BMAP_ANIMATION_BOUNCE);//BMAP_ANIMATION_DROP
            xx.marker=marker;
          
        }

      })
      //marker=that.markers[0]
      //that.map.removeOverlay(marker);
       //p1=c.statXY.split("|")[0];
       //p2=c.statXY.split("|")[1];
       //point = new BMap.Point(p1,p2);
      //marker.setPosition(point)
    },
    showworkpolyline(idx,works){
      //this.worksLocation[idx].lng
      if (!this.showSelectWork) return;
      let that=this
      let color=['#666','#67c23a','#f56c6c','#107ff2','#dc8c14','a6a9b0','#666','#67c23a','#f56c6c','#107ff2','#dc8c14','a6a9b0']
      let pointwp=new BMap.Point(this.worksLocation[idx].lng,this.worksLocation[idx].lat);
      let workrow=works.shift();
      //console.log(idx,works.length,workrow)
      let pointw =new BMap.Point(workrow.lng,workrow.lat);
      //let pois = [new BMap.Point(xx.lng,xx.lat),point2];
      this.worksLocation[idx].precid=workrow.recid
      let js=works.length
      let ys=(3000/js)>500 ? 500 : (3000/js<100?100:3000/js)
      if (js>0){
        this.mytimers.push(setTimeout(() => {
          that.showworkpolyline(idx,works);
        }, ys));
      }
      if (this.map.getDistance(pointwp,pointw)>5){
        //console.log(idx,works.length,workrow)
        let iconWork2 = new BMap.Icon("http://www.yh59481717.com/images/map-6.png", new BMap.Size(44, 50),{imageOffset:new BMap.Size(12, 0)});
        let iconWork = new BMap.Icon("http://www.yh59481717.com/images/map-4.png", new BMap.Size(44, 50),{imageOffset:new BMap.Size(12, 0)});
        let iconStat = new BMap.Icon("http://www.yh59481717.com/images/map-2.png", new BMap.Size(44, 50),{imageOffset:new BMap.Size(12, 0)});
        
        
        let polyline =new BMap.Polyline([pointwp,pointw], {
          enableEditing: false,//是否启用线编辑，默认为false
          //enableClicking: true,//是否响应点击事件，默认为true
          //icons:[iconLine],
          //strokeStyle:'dashed',
          strokeWeight:'6',//折线的宽度，以像素为单位
          strokeOpacity: 0.9,//折线的透明度，取值范围0 - 1
          strokeColor:color[idx] //折线颜色
        });
        this.map.addOverlay(polyline);
        this.worksLocation[idx].precid=workrow.recid
        if ((typeof this.worksLocation[idx].marker) != 'undefined') 
          this.map.removeOverlay(this.worksLocation[idx].marker);
        this.worksLocation[idx].lng=workrow.lng;
        this.worksLocation[idx].lat=workrow.lat;
        //xx.recid=w.recid
        let label = new BMap.Label("<div style='padding:1px;font-size:9px;'>"+
        this.worksLocation[idx].station+"-"+this.worksLocation[idx].workname+"</div>",
        {point:pointw,offset:new BMap.Size(-6,-20)});
        label.setStyle({background:"#bb009b",color:"#eee",cursor:"pointer"});
        let marker = new BMap.Marker(pointw, { enableDragging: true,icon:iconWork});
        marker.setLabel(label);
        if (this.worksLocation[idx].workname==this.orderHead.m_worker){
          marker.setIcon(iconWork2)
          label.setStyle({background:"#a0cfff",color:"#333",cursor:"pointer"});
        }
        this.map.addOverlay(marker);
        //marker.setAnimation(BMAP_ANIMATION_BOUNCE);
        this.worksLocation[idx].marker=marker;
        //xx.precid=w.recid
        console.log(idx,works.length,workrow.mobile)
      }

    },
    saveaddress() {
      var that = this
      let savelist=this.stationList.filter(item => {
				return item.ismodi==="1";
			})
      if (savelist.length==0){
        that.$message.warning("记录未变动，无需保存");
        return;
      }
      that.$confirm("真的要保存吗?", "确认操作", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "info",
          iconClass: "el-icon-question",
        })
        .then(() => {
          let data = {
            lx: "savestation",
            procext:"yhjj",
            sqlone: JSON.stringify(savelist),
          };
          that.$api.post("",data,
            (res) => {
              if (res["status"] == "1"){
                that.$message.success(res["message"]);
                that.stationList.forEach((s) => {
                  s.ismodi="0";
                })
              }else
                that.$message.error(res["message"]);
            },
            (failure) => {
              //this.showUserInfo = false;
              that.$message.error(failure);
            }
          );
        })
        .catch(() => {});
    },
    SetWorker(worker){
      console.log(worker)
      this.$confirm("真的要选择【"+worker+"】作为送气工吗?", "确认操作", {
          confirmButtonText: "确定",cancelButtonText: "取消",
          type: "info",iconClass: "el-icon-question",
        })
        .then(() => {
          this.orderHead.m_worker=worker
          this.showSelectWork=false
          })
      .catch(() => {});
    },
    clearLxmobile(recid){
      if (this.StaffID==""){
        this.$message.error("此操作无权限");
        return;
      }
      var idx=this.orderlists.findIndex(item=>item.recid==recid)
      let kh=this.orderlists[idx].contact+this.orderlists[idx].m_xdtime
      this.$confirm("真的要清除【"+kh+"】的呼叫联系电话吗?", "确认操作", {
          confirmButtonText: "确定",cancelButtonText: "取消",
          type: "info",iconClass: "el-icon-question",
        })
        .then(() => {
          let data = {
            procext:"order",
            lx: "cancellxmobile",
            sqlone:recid,
          };
          this.$api.post("",data,
            (res) => {
              this.$message.success(res["message"]);
              if (res["status"] == "1") {
                this.orderlists[idx].m_lxmobile=res["m_lxmobile"];
                return
              } 
              if (res["status"] == "-2") {
                this.$parent.$parent.$parent.swapToken();
              }
            },
            (failure) => {
              //this.showUserInfo = false;
              this.$message.error(failure);
            }
          );
        })
      .catch(() => {});
    },
    MakeOrder(yylx,yymc){
      if (this.StaffID==""){
        this.$message.error("此操作无权限");
        return;
      }
      for(var k in this.orderHead){
        this.orderHead[k]=this.$api.myFilter(this.orderHead[k].trim())
      }
      let mobile=this.orderHead.mobile;//表头手机号
      let phone=this.CallerNum; //呼叫号
      if (mobile==""){
        mobile=phone
        this.orderHead.mobile=phone
      }
      if (!(mobile && this.$api.checkMobile(mobile))) {
        this.$message.error("联系电话格式不对");
        return;
      }
      if (this.orderHead.contactaddr.length<5){
        this.$message.error("地址太短了，至少4个字啊");
        return;
      }
      if (phone!="" && phone!=mobile && this.orderHead.contactaddr.indexOf(phone)==-1){
        if (!this.$api.checkMobile(phone)) {
          this.$message.error("呼叫电话格式不对");
          return;
        }
        //this.orderHead.contactaddr+='('+phone+')'
        this.orderHead.m_lxmobile=phone
      }
      let sfz=this.orderHead.m_identity
      let sfzmsg=sfutil.SFID(sfz);
      if (sfzmsg){
        this.$message.error(sfzmsg);
        return;
      }
      let m_syzbh=this.orderHead.m_syzbh
      if (m_syzbh && !(/^[0-9]\d{6,11}$/.test(m_syzbh))){
        this.$message.error("使用证有错误7-11位");
        return;
      }
      if (this.orderHead.station.length!=2){
        this.$message.error("请选择正确的所属站点");
        return;
      }
      let data = {
        procext:"order",
        lx: "Saveorder",
        sqlone:JSON.stringify(this.orderHead),
        sqltwo:yylx
      };
      this.$api.post("",data,
        (res) => {
          if (res["status"] == "1") {
            let js=res["js"];
            let jl=res["records"];
            if (js=="1")
              this.$message.success(res["message"]);
            else
              this.$message.error(res["message"]);
            if (jl.length==1){
              this.orderlists.unshift(jl[0])
              this.handleCurrentChange(jl[0])
            }
            console.log(jl)
            return
          }
          this.$message.error(res["message"]);
          if (res["status"] == "-2") {
            this.$parent.$parent.$parent.swapToken();
          }
        },
        (failure) => {
          //this.showUserInfo = false;
          this.$message.error(failure);
        }
      );
    },
    EditOrder(){
      if (this.StaffID==""){
        this.$message.error("此操作无权限");
        return;
      }
      let recid=this.xsrecid
      if (recid<1){
        this.$message.error("请选择一个记录");
        return;
      }
      var idx=this.orderlists.findIndex(item=>item.recid==recid)
      for(var k in this.orderHead){
        this.orderHead[k]=this.$api.myFilter(this.orderHead[k].trim())
      }
      let kh=this.orderlists[idx].contact+this.orderlists[idx].m_xdtime
      let m_mobile=this.orderlists[idx].m_mobile;//订单手机号
      let mobile=this.orderHead.mobile;//表头手机号
      let phone=this.CallerNum; //呼叫号
      if (m_mobile!=mobile){
        this.$message.error("订单的电话不能修改");
        return;
      }
      if (phone!="" && phone!=mobile && this.orderHead.contactaddr.indexOf(phone)==-1){
        if (!this.$api.checkMobile(phone)) {
          this.$message.error("呼叫电话格式不对");
          return;
        }
        this.orderHead.contactaddr+='('+phone+')'
      }
      let sfz=this.orderHead.m_identity
      let sfzmsg=sfutil.SFID(sfz);
      if (sfzmsg){
        this.$message.error(sfzmsg);
        return;
      }
      let m_syzbh=this.orderHead.m_syzbh
      if (m_syzbh && !(/^[0-9]\d{6,11}$/.test(m_syzbh))){ //if (!(m_syzbh && /^[0-9]\d{6,7}$/.test(m_syzbh))){
        this.$message.error("使用证有错误7-11位");
        return;
      }
      if (this.orderHead.contactaddr.length<2){
        this.$message.error("地址太短了，至少2个字啊");
        return;
      }
      if (this.orderHead.station.length!=2){
        this.$message.error("请选择正确的所属站点");
        return;
      }
      this.$confirm("真的要修改【"+kh+"】的预约吗?", "确认操作", {
          confirmButtonText: "确定",cancelButtonText: "取消",
          type: "info",iconClass: "el-icon-question",
        })
        .then(() => {
          let data = {
            procext:"order",
            lx: "editorder",
            sqlone:JSON.stringify(this.orderHead),
            sqltwo:recid
          };
          this.$api.post("",data,
            (res) => {
              if (res["status"] == "1") {
                let js=res["js"];
                let jl=res["records"];
                if (js=="1")
                  this.$message.success(res["message"]);
                else
                  this.$message.error(res["message"]);
                
                this.orderlists[idx].m_caozuo=jl[0].m_caozuo;
                this.orderlists[idx].m_lasttime=jl[0].m_lasttime;
                this.orderlists[idx].yyr=jl[0].yyr;
                this.orderlists[idx].contact=jl[0].contact;
                this.orderlists[idx].m_station=jl[0].m_station;
                this.orderlists[idx].m_stats=jl[0].m_stats;
                this.orderlists[idx].m_caddress=jl[0].m_caddress;
                this.orderlists[idx].m_cmemo=jl[0].m_cmemo;
                this.orderlists[idx].m_yajin=jl[0].m_yajin;
                this.orderlists[idx].m_worker=jl[0].m_worker;
                this.orderlists[idx].m_identity=jl[0].m_identity;
                this.orderlists[idx].m_syzbh=jl[0].m_syzbh;
                this.orderlists[idx].YYSJ=jl[0].YYSJ;
                this.orderHead.m_syzbh=jl[0].m_syzbh;
                this.orderHead.m_identity=jl[0].m_identity;
                this.orderHead.m_yajin=jl[0].m_yajin;
                return
              } 
              this.$message.error(res["message"]);
              if (res["status"] == "-2") {
                this.$parent.$parent.$parent.swapToken();
              }
            },
            (failure) => {
              //this.showUserInfo = false;
              this.$message.error(failure);
            }
          );
        })
      .catch(() => {});
    },
    CancelOrder(){
      if (this.StaffID==""){
        this.$message.error("此操作无权限");
        return;
      }
      let recid=this.xsrecid
      if (recid<1){
        this.$message.error("请选择一个记录");
        return;
      }
      var idx=this.orderlists.findIndex(item=>item.recid==recid)
      let kh=this.orderlists[idx].contact+this.orderlists[idx].m_xdtime
      if ((this.orderlists[idx].m_scantime!="")){
        this.$message.error("订单已送达："+kh);
        return;
      }
      this.$confirm("真的要取消【"+kh+"】的预约吗?", "确认操作", {
          confirmButtonText: "确定",cancelButtonText: "取消",
          type: "info",iconClass: "el-icon-question",
        })
        .then(() => {
          let data = {
            procext:"order",
            lx: "cancelorder",
            sqlone:recid,
          };
          this.$api.post("",data,
            (res) => {
              
              if (res["status"] == "1") {
                let js=res["js"];
                let jl=res["records"];
                if (js=="1")
                  this.$message.success(res["message"]);
                else
                  this.$message.error(res["message"]);
                this.orderlists[idx].m_stats=jl[0].m_stats;
                this.orderlists[idx].m_caozuo=jl[0].m_caozuo;
                this.orderlists[idx].m_lasttime=jl[0].m_lasttime;
                return
              } 
              if (res["status"] == "-2") {
                this.$parent.$parent.$parent.swapToken();
              }
            },
            (failure) => {
              //this.showUserInfo = false;
              this.$message.error(failure);
            }
          );
        })
      .catch(() => {});
    },
    changSwitch(){ //弹屏开关
      if (this.canCall){
        this.$message.success(this.StaffID+"弹屏接收已打开！在你摘机后自动获取");
        this.againcall();
      }else
        this.$message.warning("弹屏接收已关闭！");
    },
    mobileAutoComp(queryString, cb){ //联系电话建议录入
      cb(this.mobileAuto);
    },
    CallerNumAutoComp(queryString, cb){
      cb(this.CallerNumAuto);
    },
    SyzbhAutoComp(queryString, cb){
      cb(this.SyzbhAuto);
    },
    CmemoAutoComp(String, cb){
      cb(this.CmemoAuto);
    },
    YajinAutoComp(String, cb){
      cb(this.YajinAuto);
    },
    ContactAutoComp(queryString, cb){
      cb([]);
    },
    handleCurrentChange(currentRow){
      //console.log(currentRow)
      if (currentRow===null) return
      if (currentRow.length==0) return
      this.xsrecid=currentRow.recid;
      this.orderHead.mobile=currentRow.m_mobile;
      this.orderHead.contact=currentRow.contact;
      this.orderHead.m_syzbh=currentRow.m_syzbh; 
      this.orderHead.contactaddr=currentRow.m_caddress;
      this.orderHead.station=currentRow.m_station;
      this.orderHead.m_cmemo=currentRow.m_cmemo;
      this.orderHead.m_yajin=currentRow.m_yajin;
      this.orderHead.m_identity=currentRow.m_identity,
      this.orderHead.m_worker=currentRow.m_worker;
      this.orderHead.m_scantime=currentRow.m_scantime;
      this.orderHead.zdy2=currentRow.zdy2;
      this.orderHead.anjian=currentRow.anjian;
      //this.orderHead.timeval=currentRow.timeval;
      this.changeSelect()
    },
    tableHeaderColor({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        return 'background-color: #f0f0f0;'
        }
    },
    changeSelect(){ 
      let station=this.orderHead.station;
      this.worksList=(station=="New" || station=="")?this.worksLists:this.worksLists.filter(item=>item.m_station==station)
    },
    queryOrderList(iResoure,queryval){ //根据呼叫电话查信息
      let val=this.$api.myFilterno(queryval.trim());
      if (iResoure=='CallerNum' || iResoure=='mobile'){
        if (!this.$api.checkMobile(val)) {
          this.$message.error("手机号有误，8、11位的数字");
          return;
        }
        if (iResoure=='CallerNum'){
          if (this.CallerNumAuto.findIndex(item=>item.value==val)==-1){
            if (this.CallerNumAuto.length>6){
              this.CallerNumAuto.pop();
            }
            this.CallerNumAuto.unshift({"value":val})
          }
        }
        if (iResoure=='mobile'){
          if (this.mobileAuto.findIndex(item=>item.value==val)==-1){
            if (this.mobileAuto.length>6){
              this.mobileAuto.pop();
            }
            this.mobileAuto.unshift({"value":val})
          }
        }
      }
      if (iResoure=='m_syzbh'){
        if (val.length<7) {
          this.$message.error("使用证号码太短了，至少7位");
          return;
        }       
        if (this.SyzbhAuto.findIndex(item=>item.value==val)==-1){
          if (this.SyzbhAuto.length>6){
            this.SyzbhAuto.pop();
          }
          this.SyzbhAuto.unshift({"value":val})
        } 
      }
      if (val==""){
        this.$message.error("关键字不能为空");
        return
      }
      this.getOrderList(iResoure,val);
    },
    EnterFun(e,iResoure,queryval){
      //var keyCode = window.event? e.keyCode:e.which;
      //if (keyCode!=13) return
      //console.log(this.CallerNum,iResoure)
      this.queryOrderList(iResoure,queryval)
    },
    initDivSize() {
      this.isPc=this.$store.getters.GettabBarInfo("isPc");
      let divwidth=this.$store.getters.GettabBarInfo("tabBarWidth");
      console.log(this.isPc,"aa",divwidth)
      if (this.isPc){
        this.pagelayout="total,sizes,prev,pager,next,jumper"
      }else{
        this.pagelayout="total,prev,pager,next"
      }
      //tabBarWidth=tabBarWidth
      //if (this.StaffID && this.isPc){

      //}
      if (!this.isPc || divwidth<950){
        this.divwidth=divwidth;
      }else{
        if (divwidth*0.8<950){
          this.divwidth=950;}
        else{this.divwidth=divwidth*0.8}
      }
      if (this.$refs.elmaincards){
        this.$nextTick(() => {
          //监听到事件后执行的业务逻辑
          
                     //console.log(element.offsetWidth,element.offsetHeight);       
                 
          
       
        let elmaincard = this.$refs.elmaincards.$el.offsetWidth*0.4
        let elmaincardh = this.$refs.container.$el.offsetHeight*0.9
        this.elmaincardh =elmaincardh;
        this.elmaincard = elmaincard<350?350:elmaincard;
        console.log("this.elmaincard",elmaincard,this.elmaincard,elmaincardh);
        });
      }
    },
    getSaleBaseInfo(){
      let data = {
        lx: "getsalebaseinfo",
        procext:"order"
      };
      this.$api.post("",data,
        (res) => {
          if (res["status"] == "1") {
            this.StaffID=res.StaffID;
            this.StaffName=res.StaffName;
            this.stationLists=res.records;
            this.worksLists=res.recordc;
            //this.worksList=res.recordc;
            this.mytimers.push(setTimeout(() => {
              this.changeSelect()
            }, 500));
          }
          if (res["status"] == "-2") {
            this.$parent.$parent.$parent.swapToken();
          }
        },
        (failure) => {}
      );
    },
    againcall(){
      if (!this.canCall || this.LoginInfo.Logined!="Yes"){
        this.$store.commit("SetHasCall",0); 
        return;
      }
      console.log("againcall..."+this.$store.getters.GetHasCall)
      let data = {
        procext:"callphone",
        lx: "callphone",
        sqlone: this.StaffID
      };
      this.$api.post("",data,
        (res) => {
          if (res["status"] == "1") {
            this.$store.commit("SetHasCall",1);
            let callphone=res.message
            if (this.CallerNum!=callphone){
              this.CallerNum=callphone
              this.queryOrderList('CallerNum',this.CallerNum);
                this.mytimers.push(setTimeout(() => {
                this.againcall();
                }, 6000));
            }else{
              this.mytimers.push(setTimeout(() => {
                this.againcall();
                }, 3500));
            }
          }else{
            this.$store.commit("SetHasCall",0); 
            this.mytimers.push(setTimeout(() => {
              this.againcall();
              }, 2000));
          }
        },
        (failure) => {}
      );
    },
    getOrderList(iResoure,queryval,pageno){
      console.log(1,typeof(queryval),queryval)
      //if (typeof(queryval)=="undefined") queryval="";
      if (typeof(pageno)=="undefined") pageno="-1";

      let lx="1";
      if (['CallerNum','mobile','m_syzbh'].indexOf(iResoure)>-1 || pageno>0){
        lx="2"}
      this.listLoading=true
      console.log(2,iResoure,queryval,pageno)
      let data = {
        lx: lx=="1"?"getstatuslist":"getorderlist",
        sqlone:iResoure,
        sqltwo:queryval,
        pageno:pageno,
        pagesize:this.pagesize,
        procext:"order"
      };
      this.$api.post("",data,
        (res) => {
          if (res["status"] == "1") {
            this.xsrecid=0;
            this.pageno=parseInt(res.pageno)
            this.totalcount=parseInt(res.count)
            if ((typeof res.recordc) != 'undefined'){
              if (res.recordc.length>0){
                this.orderHead=res.recordc[0];
              }else if (lx=="2"){
                for(var k in this.orderHead){
                  this.orderHead[k]=""
                }
              }
            }
            this.orderlists=res.records
            console.log(this.orderHead)
          }
          if (res["status"] == "-2") {
            this.$parent.$parent.$parent.swapToken();
          }
          this.listLoading=false;
        },
        (failure) => {this.listLoading=false;}
      );
    },
    myclearTimer(){
      this.mytimers.forEach(e=>{
        if (e) clearTimeout(e)
      })
      this.mytimers=[];
    },
  },
  created() {
    let logininfo = JSON.parse(sessionStorage.getItem("LoginInfo")) || "";
    if (logininfo) {
      this.LoginInfo=logininfo;
    }
    let pagesizes=JSON.parse(localStorage.getItem("pagesizes") || "{}");
    if (pagesizes && pagesizes["callsystem"])
      this.pagesize=parseInt(pagesizes["callsystem"])
    //console.log("pagesize",pagesizes)
    this.getSaleBaseInfo();
    //this.queryOrderList('CallerNum','18939978600');
  },
  mounted() {
    
    window.onresize = () => {
      //this.initDivSize();
    };
    this.mytimers.push(setTimeout(() => {
      this.initDivSize();
    }, 500));
  },
  beforeDestroy() {
    this.canCall=false;
    this.showSelectWork=false;
    this.$store.commit("SetHasCall",0);
    this.myclearTimer();
    this.map=null;
  },
}
</script>
<style src = "../../Styles/cover.css"></style>
<style src = "../../Styles/common.css"></style>
<style src = "../../Styles/iconfont/iconfont.css"></style>

<style>
.input-with-select .el-input-group__append{
    background-color: #fff !important;
  }
.container .el-main {
  padding: 8px 10px 10px 10px !important
}
.el-input-group__append .el-input-group__prepend{
  padding:0 10px;
}
.calltool .el-button--medium{
  padding: 10px;
}
</style>
<style scoped>
.myscrollbar::-webkit-scrollbar{
display:none;
}
.flex_row_left{
    display: flex;             /*设置为flex布局*/
    justify-content: flex-start;     /*水平居中*/
    align-items: center;       /*垂直居中*/
    flex-direction: row;
}
.flex_row_right{
    display: flex;             
    justify-content: flex-end; 
    align-items: center; 
    flex-direction: row;
}
.flex_row{
    display: flex;             /*设置为flex布局*/
    justify-content: center;   /*水平居中*/
    align-items: center;       /*垂直居中*/
    flex-direction: row;
}
.flex_col{
    display: flex;             /*设置为flex布局*/
    justify-content: flex-start;   /*水平居中*/
    align-items: center;       /*垂直居中*/
    flex-direction: column;
}
.subtitle{
  font-size:16px;
  color:#222222;
  line-height:30px;
  padding-left:10px;
  /* padding-top:5px;
  padding-bottom:5px; */
}
.label0{
  width:50%;
}
.label1{
  width:15%;
  text-align: center;
}
.label2{
  width:35%;
  text-align: center;
}
.labe20{
  width:30%;
}
.labe21{
  width:45%;
  text-align: center;
}
.labe22{
  width:25%;
  text-align: center;
}
.container {
  width: 100%;
  height: 100%;
  /* background-color: #a4c7f5; */
}
.el-form-item {
    margin-bottom: 0px;
}
.el-dialog{
    min-width:320px !important;
}
.el-descriptions__header{
  margin-bottom:10px !important; 
}    
.my-label{
  width:20%;
  max-width:150px;
  min-width:80px;
}
.my-content{
  min-width:100px;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}
.input1 {
  width: 200px;
}
.button {
  /* width: 100px; */
  padding-left: 10px;
  padding-right: 10px;
}
.el-card__body{
  padding:0  !important; 
}

  </style>

